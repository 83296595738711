import React from 'react';
import { Link } from 'gatsby';

import Button from '../../button/button';

const CardDetail = ({ data, styles }) => {
    if (data) {
        return (
            <div className={`${styles.card} ${styles.cardDetail}`}>
                {/* {console.log(data)} */}
                <div className={styles.cardAsset}>
                    <Link to={data.slug && `/content-hub/${data._type}/${data.slug?.current}/`}>
                        <img src={`${data.featureImage.asset.url}?w=600&h=600&fit=crop&crop=center`} alt={data.title} />
                    </Link>
                </div>
                <div className={styles.cardContent}>
                    <div className={styles.tags}>
                        {data.tags && data.tags.map((tag, tagIndex) => (
                            <Link key={tagIndex} to={`/content-hub/tag/${tag.slug?.current}/`} className={styles.tag}>
                                {tag.title}
                            </Link>
                        ))}
                    </div>
                    <h3>{data.title}</h3>
                    {data.blurb && <p>{data.blurb}</p>}
                    <Button link={data.slug && `/content-hub/${data._type}/${data.slug?.current}/`}>
                        {data._type === 'written' && <>Read</>}
                    </Button>
                </div>
            </div>
        )
    }

    return null;
}

export default CardDetail
