import React, { useEffect, useState } from 'react';
// import { Link } from 'gatsby';
import Button from '../../button/button';
import Icon from '../../icons';

const CardAudio = ({ data, styles }) => {
    const [audioPlay, setAudioPlay] = useState(false);
    const [listeners, setListeners] = useState(false);

    const clickEvent = () => {
        if (typeof window !== 'undefined') {
            const audio = document.getElementById(`cardAudio-${data.id}`);
            if (audio.paused) {
                audio.play();
            } else {
                audio.pause();
            }
        }
    }

    useEffect(() => {
        const audio = document.getElementById(`cardAudio-${data.id}`);
        if (typeof window !== 'undefined' && audio && !listeners) {
            setListeners(true);
            // console.log("Adding listeners for player", data.id);
            audio.addEventListener('play', function(e){
                setAudioPlay(true);
                var audios = document.getElementsByTagName('audio');
                for(var i = 0, len = audios.length; i < len;i++){
                    if(audios[i] != e.target){
                        audios[i].pause();
                    }
                }
            }, true);

            audio.addEventListener('pause', function(e){
                setAudioPlay(false);
            }, true);
        }
    }, []);

    return (
        <div className={`${styles.card} ${styles.cardAudio}`}>
            {/* {console.log(data)} */}
            <div className={`${styles.cardAsset}`}>
                <img src={(data.featureImage && `${data.featureImage.asset.url}?w=260&h=260&fit=crop&crop=center`) || "https://via.placeholder.com/450x500?text=Placeholder"} alt={data.title} />
            </div>
            <div className={styles.cardContent}>
                <span className={styles.overline}>{data.series.title}</span>
                <span className={styles.smallTitle}>{data.title}</span>
                <div className={styles.options}>
                    <Button size="small" onClick={() => clickEvent()}>
                        {audioPlay ? (
                            <Icon symbol="pause" />
                        ) : (
                            <Icon symbol="play" />
                        )}
                    </Button>

                    <div className={styles.audioPlayer}>
                        {data.audioUrl && (
                            <audio id={`cardAudio-${data.id}`} controls src={data.audioUrl.asset.url} />
                        )}
                    </div>

                    <div className={`${styles.listenOn} ${styles.icons}`}>Listen on <Button level="secondary" size="small" link={data.spotifyUrl}><Icon symbol="spotify" /></Button> <Button level="secondary" size="small" link={data.appleUrl}><Icon symbol="apple" /></Button> <Button level="secondary" size="small" link={data.youTubeUrl}><Icon symbol="youtube" /></Button></div>
                </div>
            </div>
        </div>
    )
}

export default CardAudio
