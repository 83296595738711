import React from 'react'
import { Link } from 'gatsby'

const Titles = ({ data, styles, quickNews }) => {
  return (
    <div className={`${styles.card} ${styles.titles} ${quickNews && styles.titlesQuickNews}`}>
      <Link to={data.slug && `/content-hub/${data._type}/${data.slug?.current}/`} />
      {!quickNews && data.tags?.length > 0 && (
        <div className={styles.tags}>
          {data.tags.map((tag, tagIndex) => (
            <Link
              key={tagIndex} to={`/content-hub/category/${tag.slug?.current}/`}
              className={styles.tag}
            >
              {tag.title}
            </Link>
          ))}
        </div>
      )}
      <div  style={quickNews ? {fontWeight: 500, color: "rgba(47, 47, 47, 1)"} : {}} className={`${styles.title} ${(quickNews && styles.newsTitle)}`}>{data.title}&nbsp;
          {!quickNews && <Link to={data.slug && `/content-hub/${data._type}/${data.slug?.current}/`} className={styles.link}>Read
          more
        </Link>}
      </div>

    </div>
  )
}

export default Titles
