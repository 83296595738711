import React from 'react'
import { Link } from 'gatsby'
import Icon from '../../icons'
import Video from '../../video/video'
import { Image } from '../../Image'
import { isExternalUrl } from '../../../lib/helpers'

const SimpleAudioColumn = ({ data, styles, section = '', options }) => {
  const contentTypeTitle = (type) => {
    switch (type) {
      case 'written':
        return 'Article'
      case 'video':
        return 'Video'
      case 'post':
        return 'News'
      default:
        return ''
    }
  }

  const link = data._type !== 'series' ? data.slug ? `/content-hub/${data._type}/${data.slug?.current}/` : '' : `/content-hub/${data.slug?.current}/`
  return (
    <div className={`${styles.card} ${options.noHover ? styles.hover : ''} ${styles.simpleAudio} ${data._type === 'video' ? styles.simpleAudioVideo : ''} ${styles[section]} ${options.red ? styles.red : ''} ${styles.simpleAudioColumn}`}>
      <Link target={isExternalUrl(link) ? '_blank' : '_self'} to={link} />
      <div className={styles.cardAsset}>
        {data._type !== 'video' ? (
          <div to={link} className={styles.cardLink}>
            <Image
              imgObj={data?.featureImage}
              props={{
                alt: data.title,
                width: options?.imgSizes ? options.imgSizes[0] : 292,
                height: options?.imgSizes ? options.imgSizes[1] : 292
              }}
            />
          </div>
        ) : (
          <div className={styles.cardLink}>
            <Image
              imgObj={data?.featureImage}
              props={{
                alt: data.title,
                width: options?.imgSizes ? options.imgSizes[0] : 292,
                height: options?.imgSizes ? options.imgSizes[1] : 292
              }}
            />
            <Video videoId={data.videoId} playerOptions={{ controls: 0, start: 20 }} type={data.youtubePlayer ? 'youtube' : 'teaser'} mute={1} />
          </div>
        )}
      </div>

      <div className={styles.cardContent}>
        <div>
          {(data.tags?.length > 0 && options?.tags) && (
            <div className={styles.cardTags}>
              {contentTypeTitle(data._type) !== '' && options?.showType !== false && <span>{contentTypeTitle(data._type)}</span>}
              <div className={styles.tags}>
                {data.tags.map((tag, tagIndex) => (
                  <Link
                    key={tagIndex} to={`/content-hub/category/${tag.slug?.current}/`}
                    className={styles.tag}
                  >
                    {tag.title}
                  </Link>
                ))}
              </div>
            </div>
          )}
          <Link target={isExternalUrl(link) ? '_blank' : '_self'} to={link} className={styles.title}>
            {data._type !== 'series' ? data.title : data.seriesTitle}
          </Link>
        </div>

        {(data.spotifyUrl || data.appleUrl || data.youTubeUrl) && (
          <div className={`${styles.listenOn} ${styles.icons}`}>Listen on
            {data.spotifyUrl && (<a className={styles.spotify} href={data.spotifyUrl} target='_blank' rel='noreferrer'><Icon symbol='spotify' /></a>)}
            {data.appleUrl && (<a className={styles.apple} href={data.appleUrl} target='_blank' rel='noreferrer'><Icon symbol='apple' /></a>)}
            {data.youTubeUrl && (<a className={styles.youtube} href={data.youTubeUrl} target='_blank' rel='noreferrer'><Icon symbol='youtube' /></a>)}
          </div>
        )}
      </div>
    </div>
  )
}

export default SimpleAudioColumn
