import React from 'react'
import { Image } from '../../Image'

const CardShort = ({ data, styles, short, page }) => {
  return (
    <div className={`${styles.card} ${styles.shortCard}`}>
      <a href={data.url} className={styles.cardLink} target='_blank' rel='noreferrer'>
        <Image imgObj={data.featureImage} props={{ width: '283', height: '480', alt: data.title }} />
      </a>
      <div className={styles.cardContent}>
        <a href={data.url} className={styles.title} target='_blank' rel='noreferrer'>
          {data.title}
        </a>
      </div>
    </div>
  )
}

export default CardShort
