import React from 'react'
import Detail from './cardDetail'
import Visual from './cardVisual'
import Micro from './cardMicro'
import Audio from './cardAudio'
import Short from './cardShort'
import Social from './cardSocial'
import SimpleCard from './simple'
import SimpleAudio from './simpleAudio'
import Titles from './titles'
import SimpleAudioColumn from './simpleAudioColumn'

import * as styles from './card.module.css'

const Card = ({ type, data, section, options = [], short, display = true, content = true, quickNews, page }) => {
  switch (type) {
    case 'short':
      return <Short page={page} data={data} styles={styles} short={short} />

    case 'social':
      return <Social page={page} data={data} styles={styles} section={section} options={options} />

    case 'simple':
    case 'simpleX':
      return <SimpleCard page={page} data={data} options={options} view={type} section={section} styles={styles} short={short} display={display} />

    case 'simpleMedia':
      return <SimpleAudio data={data} options={options} styles={styles} section={section} />

    case 'simpleAudio':
      return <SimpleAudioColumn data={data} options={options} styles={styles} section={section} />

    case 'titles':
      return <Titles quickNews={quickNews} data={data} styles={styles} />

    case 'detail':
      return <Detail data={data} styles={styles} />

    case 'visual':
      return <Visual data={data} styles={styles} display={display} />

    case 'micro':
      return <Micro data={data} styles={styles} content={content} />

    case 'audio':
      return <Audio data={data} styles={styles} />

    default:
      return null
  }
}

export default Card
