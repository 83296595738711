import React from 'react'
import { Link } from 'gatsby'
import Icon from '../../icons'
import Video from '../../video/video'
import { Image } from '../../Image'
import { isExternalUrl } from '../../../lib/helpers'

const SimpleAudio = ({ data, styles, section = '', options }) => {
  const link = data._type !== 'series' ? data.slug ? `/content-hub/${data._type}/${data.slug?.current}/` : '' : `/content-hub/${data.slug?.current}/`
  return (
    <div className={`${styles.card} ${options.noHover ? styles.hover : ''} ${styles.simpleAudio} ${data._type === 'video' ? styles.simpleAudioVideo : ''} ${styles[section]} ${options.red ? styles.red : ''}`}>
      <Link target={isExternalUrl(link) ? '_blank' : '_self'} to={link} />
      <div className={styles.cardAsset}>
        {data._type !== 'video' ? (
          <div to={link} className={styles.cardLink}>
            <Image imgObj={data.featureImage} props={{ width: '292', height: '292', alt: data.title }} />
          </div>
        ) : (
          <div className={styles.cardLink}>
            <Image imgObj={data.featureImage} props={{ width: '292', height: '292', alt: data.title }} />
            <Video videoId={data.videoId} playerOptions={{ controls: 0, start: 20 }} type={data.youtubePlayer ? 'youtube' : 'teaser'} mute={1} />
          </div>
        )}
      </div>

      <div className={styles.cardContent}>
        <div>
          {(data.series || data._type === 'series') && (<span className={styles.overline}>{data._type !== 'series' ? data.series.title : data.title}</span>)}
          <Link target={isExternalUrl(link) ? '_blank' : '_self'} to={link} className={styles.title}>
            {data._type !== 'series' ? data.title : data.seriesTitle}
          </Link>

          {(data.tags?.length > 0 && options?.tags) && (
            <div className={styles.tags}>
              {data.tags.map((tag, tagIndex) => (
                <Link
                  key={tagIndex} to={`/content-hub/category/${tag.slug?.current}/`}
                  className={styles.tag}
                >
                  {tag.title}
                </Link>
              ))}
            </div>
          )}
        </div>

        {(data.spotifyUrl || data.appleUrl || data.youTubeUrl) && (
          <div className={`${styles.listenOn} ${styles.icons}`}>Listen on
            {data.spotifyUrl && (<a className={styles.spotify} href={data.spotifyUrl} target='_blank' rel='noreferrer'><Icon symbol='spotify' /></a>)}
            {data.appleUrl && (<a className={styles.apple} href={data.appleUrl} target='_blank' rel='noreferrer'><Icon symbol='apple' /></a>)}
            {data.youTubeUrl && (<a className={styles.youtube} href={data.youTubeUrl} target='_blank' rel='noreferrer'><Icon symbol='youtube' /></a>)}
          </div>
        )}
      </div>
    </div>
  )
}

export default SimpleAudio
